import ListCourses from "../pages/ListCourses";
import Login from "../pages/Login";
import DetailCourse from "../pages/DetailCourse";
import { SenceLogin } from "../pages/Sence/login";
import { SenceLogout } from "../pages/Sence/logout";
import { SenceErrorLogin } from "../pages/Sence/error";
import Progress from "../pages/Progress";
import { ProgressDetail } from "../pages/Progress/ProgressDetail";
import ContactForm from "../pages/ContactForm";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ChangePassword from "../pages/Login/ChangePassword";
import { DetailCourseV2 } from "../pages/DetailCourse-v2/detailcourse";

export const routes = [
  {
    path: "/",
    element: Login,
    requireAuth: false,
  },
  {
    path: "/listcourses",
    element: ListCourses,
    requireAuth: true,
  },
  {
    path: "/detailcourse/:id",
    element: DetailCourseV2,
    requireAuth: true,
  },
  {
    path: "/auth/sence/login",
    element: SenceLogin,
    requireAuth: true,
  },
  {
    path: "/auth/sence/logout",
    element: SenceLogout,
    requireAuth: true,
  },
  {
    path: "/auth/sence/login/error",
    element: SenceErrorLogin,
    requireAuth: true,
  },
  {
    path: "/auth/sence/logout/error",
    element: SenceErrorLogin,
    requireAuth: true,
  },
  {
    path: "/listcourses/progress",
    element: Progress,
    requireAuth: true,
  },
  {
    path: "/listcourses/progress/:id",
    element: ProgressDetail,
    requireAuth: true,
  },
  {
    path: "/contact",
    element: ContactForm,
    requireAuth: true,
  },
  {
    path: "/change-password",
    element: ForgotPassword,
    requireAuth: false,
  },
  {
    path: "/login/forgot_password",
    element: ChangePassword,
    requireAuth: false,
  },
];
