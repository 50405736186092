import React from "react";
import { getFeedback } from "../../../services/moodle/courses";
import { useAuth } from "../../../contexts/AuthContext";

export const ProgressRowItemDetail = ({ detail }) => {
  const [feedback, setFeedback] = React.useState("-");
  const [generalFeedback, setGeneralFeedback] = React.useState("-");

  const { user } = useAuth();

  React.useEffect(() => {
    if (
      detail.itemmodule === "quiz" &&
      detail.graderaw &&
      detail.iteminstance
    ) {
      const args = {
        token: user.token,
        quizid: detail.iteminstance,
        grade: detail.graderaw,
      };
      // console.log("getting feedback of: ", detail);
      getFeedback(args).then((res) => {
        if (res.feedbacktext !== "") {
          setGeneralFeedback(res.feedbacktext);
          // console.log("setting feedback");
        }
      });
    } else {
      if (detail.feedback !== "" && feedback === "-") {
        setFeedback(detail.feedback);
      }
    }
    // console.log({ indexMediaTag, index, feedback: detail.feedback });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <tr>
        <th scope="row" className="title-row">
          {detail.itemname}
        </th>
        <td>{detail.gradeformatted || "-"}</td>
        <td>{detail.percentageformatted}</td>
        <td
          dangerouslySetInnerHTML={{
            __html: feedback,
          }}
        ></td>
        <td
          dangerouslySetInnerHTML={{
            __html: generalFeedback,
          }}
        ></td>
      </tr>
    </>
  );
};
