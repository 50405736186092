import React from "react";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { HeaderProgress } from "./Header";
import { ProgressGroup } from "./ProgressGroup";
import { ProgressTitle } from "./ProgressTitle";
import { ProgressTable } from "./ProgressTable";
import { useMoodle } from "../../contexts/MoodleContext";
import { ProgressChartItem } from "./ProgressChart";
import { ProgressRowItem } from "./ProgressRowItem";
import { PROGRESS_DEFAULT } from "../../utils/state";
import { requestAutoLogin } from "../../services/moodle/userauth";
import { useAuth } from "../../contexts/AuthContext";
import { baseURL } from "../../services/apirest";

const Progress = (/* {data} */) => {
  const [grades, setGrades] = React.useState(null);
  const moodle = useMoodle();
  const { user } = useAuth();
  const [sessionKey, setSessionKey] = React.useState(null);

  React.useEffect(() => {
    !moodle.courses && moodle.getCourses();
    fetchGrades();
    if (!sessionKey) {
      requestAutoLogin({ username: user.username }).then((res) =>
        setSessionKey(res)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchGrades() {
    const res = await moodle.getProgress();
    /*     console.log({ res }); */
    setGrades(res.grades);
  }

  return (
    <>
      <Navbar />
      {sessionKey && (
        <iframe
          src={`${baseURL}auth/userkey/login.php?key=${sessionKey}&wantsurl=${baseURL}`}
          id="login-token"
          frameBorder="0"
          width="0"
          allow="autoplay ; microphone; camera"
          height={"0"}
          title="login-token"
        />
      )}
      <div style={styles.progressBlock}>
        <div style={styles.wrapper}>
          <ProgressTitle title={PROGRESS_DEFAULT.title} />
          <ProgressTable headers={PROGRESS_DEFAULT.headers}>
            {moodle.courses &&
              grades &&
              moodle.courses.map((c, idx) => {
                let grade = grades.filter((g) => g.courseid === c.id)[0]?.grade
                  ? grades.filter((g) => g.courseid === c.id)[0].grade
                  : "-";
                return <ProgressRowItem key={idx} course={c} grade={grade} />;
              })}
          </ProgressTable>
        </div>
        <HeaderProgress />
        <ProgressGroup>
          {moodle.courses &&
            moodle.courses.map((c, idx) => {
              return <ProgressChartItem key={idx} course={c} />;
            })}
        </ProgressGroup>
      </div>
      <Footer />
    </>
  );
};

const styles = {
  wrapper: {
    paddingTop: "12rem",
  },
  progressBlock: {
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
    paddingBottom: "5rem",
  },
};

export default Progress;
