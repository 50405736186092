import React from "react";
import { palette } from "../../theme/theme";

export const ForgotPasswordForm = ({
  handleChange,
  handleSubmitUser,
  handleSubmitEmail,
  loading,
  credentials,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmitUser}
        action="https://par.tuclase.cl/login/index.php"
        method="post"
        style={{ marginTop: "15px" }}
      >
        <div className="col-md-12">
          <input
            onChange={handleChange}
            type="text"
            name="username"
            className="form-control box-ingreso2"
            placeholder="Buscar por Usuario"
            value={credentials.username}
          />
        </div>
        <div className="col-md-12">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bot-text"
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-md mx-3"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Enviar solicitud"
            )}
          </button>
        </div>
      </form>
      <form
        onSubmit={handleSubmitEmail}
        action="https://par.tuclase.cl/login/index.php"
        method="post"
      >
        <div className="col-md-12">
          <input
            onChange={handleChange}
            type="email"
            name="email"
            className="form-control box-ingreso2"
            placeholder="Buscar por Correo"
            value={credentials.email}
          />
        </div>
        <div className="col-md-12">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bot-text"
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-md mx-3"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Enviar solicitud"
            )}
          </button>
        </div>
      </form>
    </>
  );
};
