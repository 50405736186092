import React from "react";
import { palette } from "../theme/theme";
import { DARK_MODE, THEME_CONTEXT } from "../utils/theme";

export const ThemeContext = React.createContext(null);

export const ThemeProvider = ({ children }) => {
  const [mode, setMode] = React.useState(
    localStorage.getItem("darkMode") || "light"
  );

  React.useEffect(() => {
    if (mode === "dark" && window.location.pathname !== "/") {
      document.querySelector("#root").style.backgroundColor =
        DARK_MODE.SECONDARY;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    darkmodebtn: {
      backgroundColor:
        mode === "light" ? palette.turqoise : THEME_CONTEXT.DARK_MODE_BTN,
    },
    darkmodebtn2: {
      backgroundColor:
        mode === "light" ? palette.turqoise : DARK_MODE.SECONDARY,
    },
    nav: {
      backgroundColor: mode === "light" ? palette.blue : THEME_CONTEXT.NAV_DARK,
    },
    title_text: {
      color: mode === "light" ? palette.turqoise : THEME_CONTEXT.TITLE_DARK,
    },
    label_text: {
      color: mode === "light" ? "#214346" : THEME_CONTEXT.TITLE_DARK,
    },
    module_text: {
      color: mode === "light" ? "#333" : THEME_CONTEXT.TITLE_DARK,
    },
    text: {
      color:
        mode === "light" ? THEME_CONTEXT.TEXT_DAY : THEME_CONTEXT.TEXT_DARK,
    },
    background_secondary: {
      backgroundColor: mode === "light" ? "#f5f5f5" : DARK_MODE.SECONDARY,
    },
    background_primary: {
      backgroundColor: mode === "light" ? "#f5f5f5" : DARK_MODE.PRIMARY,
    },
    bg_course_group: {
      backgroundColor:
        mode === "light" ? "rgba(0, 0, 0, 0.05)" : DARK_MODE.PRIMARY,
    },
    box_temas: {
      backgroundColor: mode === "light" ? "#f8f8f8" : DARK_MODE.SECONDARY,
    },
  };

  let actions = {
    handleDarkMode: function () {
      if (mode === "light") {
        setMode("dark");
        localStorage.setItem("darkMode", "dark");
        document.querySelector("#root").style.backgroundColor =
          DARK_MODE.SECONDARY;
      } else {
        setMode("light");
        localStorage.setItem("darkMode", "light");
        document.querySelector("#root").style.backgroundColor = "#f5f5f5";
      }
    },
  };

  let value = {
    styles,
    actions,
    mode,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
