import React from 'react'
import { CustomSpinner } from "../../../components/Loader/custom.spinner"
import { setSenceSession } from '../../../utils/cookies'


export const SenceLogin = () => {
  
  React.useEffect(() => {
    if(window.location.search){
      let session = setSenceSession(true)
      if(session){
        let course = localStorage.getItem('current_course')
        localStorage.setItem('last_login_sence', new Date())
        window.location.replace(`/detailcourse/${course}`)
      }
    }
  }, [])

  return(<CustomSpinner/>)
}