import "./index.css";
import avatar from "./assets/person-circle.svg";
import logout from "./assets/box-arrow-right.svg";
import gear from "./assets/gear-wide-connected.svg";
import home from "./assets/house-fill.svg";
import brand from "./assets/log-menu.png";
import grades from "./assets/mortarboard-fill.svg";
import msg from "./assets/chat-dots-fill.svg";
import resume from "./assets/collection-play-fill.svg";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useMoodle } from "../../contexts/MoodleContext";
import { CustomDialog } from "../Dialog";
import { useMoodleV2 } from "../../contexts/moodle";

export const Sidebar = () => {
  const [open, setOpen] = React.useState(false);
  const [course, setCourse] = React.useState(null);
  const moodle = useMoodle();
  const navigate = useNavigate();
  const auth = useAuth();
  const moodlev2 = useMoodleV2();
  const handleSidebar = () => {
    let { style } = document.querySelector(".sidebar-container");

    style.left = "-320px";
  };

  const handleLogoutCompleted = () => {
    setOpen(false);
    auth.logout(moodlev2);
    navigate("/");
  };

  const handleLogout = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (moodle.currentCourse) {
      setCourse(moodle.currentCourse);
    }
  }, [moodle.currentCourse]);

  return (
    <div className="sidebar-container">
      <div className="top">
        <div className="top-content">
          <img src={gear} alt="..." />
          <img src={avatar} className="avatar" alt="..." />
          <img src={logout} alt="..." onClick={handleLogout} />
        </div>
      </div>
      <div className="center">
        <div className="center-content">
          <ul>
            <Link to={"/listcourses"} onClick={handleSidebar}>
              <li>
                <img src={home} alt="..." />
                Inicio
              </li>
            </Link>
            <Link to={"/listcourses/progress"} onClick={handleSidebar}>
              <li>
                {" "}
                <img src={grades} alt="..." />
                Historial
              </li>
            </Link>
            <Link to={"/contact"} onClick={handleSidebar}>
              <li>
                {" "}
                <img src={msg} alt="..." />
                Contacto
              </li>
            </Link>
            {course && (
              <Link to={"/contact"} onClick={handleSidebar}>
                <li>
                  {" "}
                  <img src={resume} alt="..." />
                  Continuar con {course.shortname}
                </li>
              </Link>
            )}
          </ul>
        </div>
      </div>
      <div className="bottom">
        {/*         <div>
          <img src={brand} alt="..." />
        </div> */}
        <div>
          <img src={brand} alt="..." />
        </div>
      </div>
      <CustomDialog
        open={open}
        title={"Confirmar cerrar sesión"}
        message={
          "Si desea cerrar sesión seleccione confirmar, de lo contrario puedes cancelar esta acción"
        }
        onCancel={() => setOpen(false)}
        onConfirm={handleLogoutCompleted}
      />
    </div>
  );
};
