import React, { useEffect, useState } from "react";
import "./detail.css";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CourseContentModuleRowComponent } from "../../components/ModuleContentRowComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMoodleV2 } from "../../contexts/moodle";
import { Navbar } from "../../components/Navbar";
import { useAuth } from "../../contexts/AuthContext";
import { getUserNru } from "../../services/tipddy/nru";
import { defaultSenceValidation } from "../../utils/handler";
import { SenceValidation } from "../../services/sence";
import { FormSence } from "../../services/sence/form";
import { useTimer } from "../../components/Timer";
import { CustomDialog } from "../../components/Dialog";
import { LIMIT_CRON_DIALOG } from "../../utils/state";
import { CustomSpinner } from "../../components/Loader/custom.spinner";
import { useTheme } from "../../contexts/ThemeContext";
import groupIcon from "../../assets/grupo-gris.png";

export const DetailCourseV2 = () => {
  const auth = useAuth();
  const params = useParams();
  const moodlev2 = useMoodleV2();
  const navigate = useNavigate();
  const [layout, setLayout] = useState(true);
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(null);
  const [message, setMessage] = React.useState({
    open: false,
    title: "",
    message: "",
    isBeforeunload: true,
  });
  const { limit } = useTimer();

  useEffect(() => {
    moodlev2.getCourseUserList(params.id).then((res) => {
      console.log("group: ", res);
      setGroup(res);
    });
    //eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (params.id === undefined) {
      navigate("/listcourses");
    } else {
      moodlev2
        .getCourseContentByID(params.id, true, window.location.search)
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  async function fetchNRU() {
    let args = {
      courseid: moodlev2.currentCourse.id,
      userid: auth.user.id,
    };
    return Promise.resolve(await getUserNru(args));
  }

  function isSenceRequired(isSence, nru) {
    let required = false;
    let session = localStorage.getItem("sence_session");
    if (isSence && nru && !session) {
      required = true;
    }
    return required;
  }

  async function VerifySence() {
    let data = defaultSenceValidation(moodlev2.currentCourse);
    let nru = null;
    let rawNru = await fetchNRU();
    if (rawNru && rawNru.length > 0) {
      nru = rawNru[0].rnu;
      localStorage.setItem("nru", nru);
    }
    let isRequired = isSenceRequired(data.isSence, nru);
    // console.log({ isRequired });
    if (isRequired) {
      let validation = {
        idSence: data.idSence,
        auth,
        session: null,
        isLogin: true,
        nru,
      };
      localStorage.setItem("idSence", data.idSence);
      localStorage.setItem("current_course", params.id);
      return SenceValidation(validation);
    } else {
      return setLoading(false);
    }
  }

  React.useEffect(() => {
    if (moodlev2.currentCourse && localStorage.getItem("last_login_sence")) {
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (e) {
          e.returnValue = "Sure?";
        }
        return "Sure?";
      };
      setMessage({
        ...message,
        open: true,
        title: "Bienvenido a su curso Sence",
        message:
          "No olvides cerrar sesión Sence al finalizar, cuando queden 10 segundos de sesión se redireccionará automáticamente a Sence para cerrar su sesión",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodlev2.currentCourse]);

  React.useEffect(() => {
    if (
      localStorage.getItem("last_login_sence") &&
      limit > LIMIT_CRON_DIALOG &&
      message.isBeforeunload
    ) {
      window.onbeforeunload = function (e) {
        return null;
      };
      setMessage({
        ...message,
        open: true,
        title: "Quedan 2 minutos para cerrar sesión",
        message:
          "Si desea cerrar sesión manualmente, haga click en cerrar Sence o espere 2 minutos",
        isBeforeunload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  React.useEffect(() => {
    if (moodlev2.currentCourse) {
      // console.log(moodlev2.currentCourse);
      VerifySence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodlev2.currentCourse]);

  function handleContentLayout() {
    setLayout(!layout);
  }

  return (
    <>
      {loading && <CustomSpinner />}
      <FormSence />
      <Navbar layout={layout} handleContentLayout={handleContentLayout} />
      <DetailCourseWrapper>
        <CoreVisualContent
          moodlev2={moodlev2}
          token={auth.user.token}
          layout={layout}
          handleContentLayout={handleContentLayout}
        />
        <CourseContentContainer
          handleContentLayout={handleContentLayout}
          layout={layout}
        >
          <DiagnosticEvaluationButton />
          <TabsRow />
          {group && <GroupRow group={group} />}
          <ContentPickerContainer>
            {moodlev2.currentModules &&
              moodlev2.currentModules.map((module, idx) => {
                return (
                  <CourseContentModuleRowComponent
                    module={module}
                    key={module.id}
                    moodle={moodlev2}
                    idx={idx}
                  />
                );
              })}
            {!moodlev2.currentModules && <SkeletonModules />}
          </ContentPickerContainer>
        </CourseContentContainer>
      </DetailCourseWrapper>
      <CustomDialog
        open={message.open}
        title={message.title}
        message={message.message}
        onCancel={() => setMessage({ ...message, open: false })}
        onConfirm={() => setMessage({ ...message, open: false })}
      />
    </>
  );
};

const SkeletonModules = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((k, i) => {
        return <SkeletonModule key={i} />;
      })}
    </>
  );
};

const SkeletonModule = () => {
  return (
    <div
      style={{ display: "flex", padding: "1.2rem 2rem", alignItems: "center" }}
    >
      <Skeleton
        baseColor="#adb5bd"
        style={{
          opacity: 0.1,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          marginRight: "1rem",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "350px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "320px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "220px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};

const DetailCourseWrapper = ({ children, token }) => {
  const theme = useTheme();
  return (
    <div
      className="detail-course-container"
      style={{
        backgroundColor: theme.mode === "light" ? "#FAFAFA" : "#333333",
      }}
    >
      <div className="row mx-0 " style={{ height: "92vh", maxHeight: "92vh" }}>
        {children}
      </div>
    </div>
  );
};

const CoreVisualContent = ({
  moodlev2,
  token,
  layout,
  handleContentLayout,
}) => {
  let embed = moodlev2.pageEmbed;
  const auth = useAuth();
  const frame = React.useRef(null);

  React.useEffect(() => {
    let refreshTrigger = localStorage.getItem("refresh");
    let newaccess = Number(localStorage.getItem("newaccess"));
    if (!refreshTrigger && newaccess) {
      let last = newaccess;
      let new_ = auth.user.id;
      let refresh = last === new_ ? false : true;
      if (refresh && frame.current !== null) {
        frame.current.addEventListener("load", () => {
          let url = window.location.pathname;
          localStorage.setItem("refresh", true);
          localStorage.removeItem("newaccess");
          window.location.replace(`${url}?watch=${new Date().getTime()}`);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Inicio Image Embed code */

  const handleImgToken = (html, token) => {
    // Crear un elemento div para cargar el HTML como texto
    let src_token_with_param = `?token=${token}`;
    let src_token_without_param = `&token=${token}`;
    let div = document.createElement("div");
    div.innerHTML = html;

    // Obtener todas las etiquetas de imagen
    let imagenes = div.getElementsByTagName("img");

    // Recorrer todas las etiquetas de imagen y agregar el token al final del atributo src
    for (let i = 0; i < imagenes.length; i++) {
      let src = imagenes[i].getAttribute("src");
      if (src.includes("?")) {
        imagenes[i].setAttribute("src", src + src_token_without_param);
      } else {
        imagenes[i].setAttribute("src", src + src_token_with_param);
      }
    }

    // Devolver el HTML modificado
    return div.innerHTML;
  };

  if (embed && embed.includes("<img")) {
    let new_embed = handleImgToken(embed, token);

    return (
      <div
        className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
        style={{
          position: "relative",
          height: "100%",
          borderBottom: "2px solid #ccc",
          transition: "0.5s",
        }}
      >
        <div
          ref={frame}
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: new_embed }}
        ></div>
        <NavigationButtons />
      </div>
    );
  }

  /* Fin Image Embed */
  if (embed && !embed.startsWith("http")) {
    return (
      <div
        className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
        style={{
          position: "relative",
          height: "100%",
          borderBottom: "2px solid #ccc",
          transition: "0.5s",
        }}
      >
        <div
          ref={frame}
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: embed }}
        ></div>
        <NavigationButtons />
      </div>
    );
  }

  return (
    <div
      className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
      style={{
        position: "relative",
        minHeight: "100%",
        padding: "0 0 80px 0",
        transition: "0.5s",
      }}
    >
      <iframe
        ref={frame}
        src={embed}
        id="video-player"
        frameBorder="0"
        width="100%"
        allow="autoplay ; microphone; camera"
        height={"99%"}
        title="video"
        style={{ borderBottom: "2px solid #ccc" }}
      />
      <NavigationButtons />
    </div>
  );
};

const CourseContentContainer = ({ children, layout }) => {
  return (
    <div
      className={layout ? "col-xl-3" : "col-xl-0"}
      style={{
        height: "100%",
        maxHeight: "100%",
        padding: "0.5rem",
        borderLeft: "1px solid rgb(104, 183, 202)",
        position: "relative",
        transition: "0.5s",
      }}
    >
      {children}
    </div>
  );
};

const DiagnosticEvaluationButton = () => {
  const moodlev2 = useMoodleV2();
  const [, /* isCompleted */ setCompleted] = useState(false);

  React.useEffect(() => {
    // console.log(moodlev2.diagnostic);
    if (moodlev2.diagnostic && moodlev2.diagnostic.length > 0) {
      let evaluation = moodlev2.diagnostic[0];
      let isCompleted = evaluation.completiondata?.state !== 0 || null;
      setCompleted(isCompleted);
    }
  }, [moodlev2.diagnostic]);

  // console.log(moodlev2.diagnostic);

  if (!moodlev2.diagnostic) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn btn-primary btn-lg btn-block btn-evaluacion"
    >
      Realizar Evaluación Diagnóstica
    </button>
  );
};

const TabsRow = () => {
  const moodle = useMoodleV2();
  const theme = useTheme();

  React.useEffect(() => {
    moodle.humhub && console.log("moodle.humhub: ", moodle.humhub);
  }, [moodle.humhub]);

  return (
    <div className="row mx-0 tabs-row">
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-4 btn btn-primary btn-tab-course"
            : "col-md-6 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.contentSelected()}
        style={{
          backgroundColor:
            moodle.selectedView === 0
              ? theme.mode === "light"
                ? "#1d24a6"
                : "rgb(3, 23, 149)"
              : "#fff",
          color: moodle.selectedView === 0 ? "#fff" : "#7D7D7D",
        }}
      >
        Contenido
      </button>
      {moodle.humhub && (
        <button
          type="button"
          className="col-md-4 btn btn-primary btn-tab-course"
          onClick={() => moodle.humhubSelected()}
          style={{
            backgroundColor:
              moodle.selectedView === 1
                ? theme.mode === "light"
                  ? "#7D7D7D"
                  : "#FFCC00"
                : "#fff",
            color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
          }}
        >
          Comunidad
        </button>
      )}
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-4 btn btn-primary btn-tab-course"
            : "col-md-6 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.onlineSelected()}
        style={{
          backgroundColor: moodle.selectedView === 2 ? "#69b7cb" : "#fff",
          color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
        }}
      >
        Sesiones en línea
      </button>
    </div>
  );
};

const GroupRow = ({ group }) => {
  return (
    <div className="row mx-0 group-row">
      <div className="group-text">
        <img className="group-icon" src={groupIcon} alt="group icon" />
        Grupo: {group}
      </div>
    </div>
  );
};

const ContentPickerContainer = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      className="content-container"
      style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        overflowY: "scroll",
        height: "100%",
        position: "relative",
        paddingBottom: 160,
        backgroundColor: theme.mode === "light" ? "#b4b4b4" : "#b4b4b4",
      }}
    >
      {children}
    </div>
  );
};

const NavigationButtons = () => {
  const theme = useTheme();
  const moodlev2 = useMoodleV2();

  const styles = {};

  styles.container = {
    position: "absolute",
    bottom: 35,
    right: 10,
    zIndex: 1000,
    width: "fit-content",
    display: "flex",
    backgroundColor: "transparent",
    padding: "0rem",
  };

  styles.innerContainer = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #b2b2b2",
    borderRadius: 9,
  };

  styles.prevButton = {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: 42,
    width: 50,
    backgroundColor: theme.mode === "dark" ? "#FAFAFA" : "#1D24A6",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.2rem",
  };

  styles.nextButton = {
    backgroundColor: theme.mode === "dark" ? "#999999" : "#1FCAC6",
    color: theme.mode === "dark" ? "#fff" : "#fff",
    height: 42,
    width: "100%",
    padding: "1.2rem",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor:
      moodlev2.nextResourceName === "Fin del curso" ? "default" : "pointer",
  };

  styles.arrowRightIcon = { transform: "translateY(1px)" };

  styles.arrowLeftIcon = { color: theme.mode === "dark" ? "#000" : "#fff" };

  const handleNext = async () => {
    if (moodlev2.nextResourceName === "Fin del curso") {
      return;
    } else {
      await moodlev2.navigateNext();
    }
  };

  const handlePrev = async () => {
    await moodlev2.navigatePrev();
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.prevButton} onClick={handlePrev}>
          <KeyboardArrowLeftIcon sx={styles.arrowLeftIcon} />
        </div>
        <div style={styles.nextButton} onClick={handleNext}>
          <p className="m-0">{moodlev2.nextResourceName}</p>
          {moodlev2.nextResourceName &&
          moodlev2.nextResourceName === "Fin del curso" ? (
            <></>
          ) : (
            <KeyboardArrowRightIcon sx={styles.arrowRightIcon} />
          )}
        </div>
      </div>
    </div>
  );
};
