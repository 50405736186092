export const MIME_TYPES = {
  png: "image/png",
  pdf: "application/pdf",
  xslx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
};

export const TIPDDY_MODULES = {
  assign: "assign",
  video: "video",
  vimeo: "vimeo",
  pdf: "pdf",
  folder: "folder",
  forum: "forum",
  zoom: "zoom",
  teams: "teams",
  meet: "meet",
  chat: "chat",
  page: "page",
  xslx: "xslx",
  doc: "doc",
  urlexternal: "url.external",
  label: "label",
  title: "title",
  feedback: "feedback",
  quiz: "quiz",
  scorm: "scorm",
  diagnostic: "Evaluación diagnóstica",
  summary: "summary",
  resource: "resource",
  png: "png",
  choice: "choice",
  url: "url",
  data: "data",
  customcert: "customcert",
  finalfeedback: "finalfeedback",
  schedule: "schedule",
  h5pactivity: "h5pactivity",
  hvp: "hvp",
  humhub: "humhub",
};

export const MOODLE_MODULES = {
  forum: "forum",
  folder: "folder",
  label: "label",
  page: "page",
  zoom: "zoom",
  assign: "assingn",
  chat: "chat",
  resource: "resource",
  feedback: "feedback",
  quiz: "quiz",
  url: "url",
  scorm: "scorm",
};

export const MOODLE_STRINGS = {
  TIMEOPEN: "timeopen",
  TIMECLOSE: "timeclose",
  ONLINE: "online",
  PROGRAMS: "programs",
  SCHEDULE: "horario",
};

export const TIPDDY_URLS = {
  TIP_PLAYER: "https://tipconsoladev.tipddy.cl/?config=iframe",
};
