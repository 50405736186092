import React from "react";
import logo from "../../assets/login-logo-blanco.png";

const Wrapper = ({ children }) => {
  return (
    <>
      <div className="container esp-log">
        <img
          src="https://par.tuclase.cl/loginalt/images/logo2.png"
          alt="logo"
          className="posi-logo posi-log-b"
        />
      </div>
      <div className="container" style={{ margin: "0", width: "100%" }}>
        <div className="row mx-4">
          <div className="col-lg-5 text-start salir">
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4"></div>
            </div>
            <h1 className="llamado">
              Elija su nueva <br />
              contraseña.
            </h1>
            <p className="llamado-d"></p>
          </div>
          <div className="col-lg-7 trans2 sac-new">
            <div className="fon-logo-new2 sec-desktop">
              <p className="">
                <img src={logo} alt="..." />
              </p>
              <p className="iniciar-new">Cambiar Contraseña</p>
            </div>
            <div className="fon-logo-new2 sec-movil">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-xs-8 col-md-8">
                    <p className="">
                      <img alt="img" src={logo} className="img-responsive" />
                    </p>
                  </div>
                  <div className="col-xs-4 col-md-4"></div>
                </div>
                <p className="iniciar-new">Cambiar Contraseña</p>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wrapper;
