import React, { useRef } from "react";
import flecha_s from "./assets/flecha-s.png";
import "./index.css";
import { removeHTML } from "../../utils";
import { useNavigate } from "react-router-dom";
import { DEFAULT_HEADER_DATA } from "../../utils/state";
import { useTheme } from "../../contexts/ThemeContext";
import { HeaderSkeleton } from "./skeleton";
import defaultBanner from "./assets/v2.jpg";

/* to work on */
export const HeaderCourses = ({ moodle }) => {
  const theme = useTheme();
  const header = useRef(null);
  /*   const banner = React.useRef(null) */
  const [state, setState] = React.useState(DEFAULT_HEADER_DATA);
  const navigation = useNavigate();

  React.useEffect(() => {
    if (moodle.courses) {
      setTimeout(() => {
        setState({
          course: moodle.courses[0],
          text: removeHTML(moodle?.courses[0]?.summary),
        });
      }, 700);
    }
  }, [moodle]);

  React.useEffect(() => {
    if (moodle.courses && moodle.courses.length > 0) {
      moodle
        .getBanner(moodle.courses[0]?.id)
        .then((banner) => {
          if (banner && header.current) {
            header.current.style.backgroundImage = `url(${banner})`;
          } else if (!banner && header.current) {
            header.current.style.backgroundImage = `url(${defaultBanner})`;
          }
        })
        .catch((err) => console.log(err));
    }
  }, [moodle]);

  /*   React.useEffect(() => {
    let item = moodle.courses[0];
    if (item?.overviewfiles?.length > 0) {
      let imgurl = moodle.getCourseImg(item);
      header.current.style.backgroundImage = `url(${imgurl})`;
    }
  }, []); */

  const handleNavigation = () => navigation(`/detailcourse/${state.course.id}`);

  return (
    <div style={{ paddingTop: 80 }}>
      <header style={{ padding: "20px 0", minHeight: 450 }} ref={header}>
        <div className="container mar">
          <div className="col-md-12">
            <div className="row">
              {!state.course ? (
                <HeaderSkeleton />
              ) : (
                <>
                  <div className="col-md-8">
                    <h1 className="tit-principal" style={{ padding: 0 }}>
                      {state.course.fullname}
                    </h1>
                    <p className="text-generico"></p>
                    <p className="text-generico-res">{state.text}</p>
                    <p className="text-generico-res">
                      <button
                        type="button"
                        className="btn btn-primary bot-generico"
                        style={theme.styles.darkmodebtn}
                        onClick={handleNavigation}
                      >
                        <img src={flecha_s} alt="..." className="" />
                        Entrar al curso
                      </button>
                    </p>
                  </div>
                </>
              )}
              <div className="col-md-4 pos-img"></div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
