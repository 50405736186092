import "./index.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ResourceLabelComponent } from "../ResourceLabelComponent";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";

const LABEL = "label";

export const CourseContentModuleRowComponent = ({
  module,
  moodle,
  idx,
  container,
}) => {
  const theme = useTheme();
  const rowContainer = useRef(null);
  const [newmodules, setnewmodules] = useState(null);
  const [isCurrentModule, setCurrentModule] = useState(null);
  const [isUserVisible, setUserVisible] = useState(true);
  const [spreed, setSpreed] = useState(false);

  useEffect(() => {
    let filtered = module.modules.filter((res) => res.modname !== LABEL);
    setnewmodules(filtered);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserVisible(module.uservisible);
  }, [module.uservisible]);

  useEffect(() => {
    let isCurrent = module.id === moodle.currentModuleId;
    setCurrentModule(isCurrent);
    isCurrent ? setSpreed(true) : setSpreed(false);

    // eslint-disable-next-line
  }, [moodle.currentModuleId]);

  const handleSpreed = async (module) => {
    module.modules.length > 0 && setSpreed(!spreed);
    await handleSummary(module);
  };

  async function handleSummary(module) {
    if (module.summary !== "") {
      await moodle.requestPageEmbed({ pageurl: module.summary, module });
    }
  }

  return (
    <div
      ref={rowContainer}
      className="wrapper-row"
      style={{
        backgroundColor: isCurrentModule
          ? theme.mode === "dark"
            ? "#222222"
            : "rgb(3, 23, 149)"
          : "#B4B4B4",
        border: isCurrentModule ? "1px solid #fff" : "unset",
        opacity: 1,
        pointerEvents: !isUserVisible ? "none" : "unset",
      }}
    >
      <div className="row-container" onClick={() => handleSpreed(module)}>
        <div className="circle-btn-container mx-2">
          <div
            className="circle-btn"
            style={{ backgroundColor: isCurrentModule ? "#fff" : "#fff" }}
          >
            <p style={{ color: "#000" }}>{idx + 1}</p>
          </div>
        </div>

        <div className="title-container" style={{ width: "100%" }}>
          <div
            className="title-btn"
            style={{
              fontSize: "14px",
              color: isCurrentModule
                ? theme.mode === "dark"
                  ? "#fff"
                  : "#fff"
                : "#000",
            }}
          >
            {module.name}
          </div>
          <div>
            {module.hasProgress && (
              <ProgressBar
                progress={module.rawProgress}
                isCurrentModule={isCurrentModule}
              />
            )}
          </div>
          {module.availabilityinfo && (
            <p
              style={{ fontSize: 10, marginTop: 2, opacity: 0.6 }}
              dangerouslySetInnerHTML={{ __html: module.availabilityinfo }}
            ></p>
          )}
        </div>

        {newmodules && newmodules.length > 0 && (
          <div>
            {spreed ? (
              <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: "#fff" }} />
            )}
          </div>
        )}
      </div>
      <div
        style={{
          paddingLeft: "26px",
        }}
      >
        {spreed &&
          newmodules &&
          newmodules.map((resource) => {
            return (
              <ResourceLabelComponent
                container={container}
                module={module}
                resource={resource}
                key={resource.id}
                moodle={moodle}
                idx={idx}
              />
            );
          })}
      </div>
    </div>
  );
};

const ProgressBar = ({ progress, isCurrentModule }) => {
  let width = "100";
  let progressWidth = (progress * width) / 100;
  const theme = useTheme();

  return (
    <div
      className="title-container"
      style={{
        transform: "translateY(10px)",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: `${width}%`,
          height: 5,
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            backgroundColor: "#1FCAC6",
            width: `${progressWidth}%`,
            height: 5,
            borderRadius: "5px",
          }}
        ></div>
      </div>
      <span
        style={{
          color: isCurrentModule
            ? theme.mode === "dark"
              ? "#fff"
              : "#fff"
            : "#000",
          fontSize: "10px",
          marginTop: "5px",
        }}
      >
        {progress}% completado
      </span>
    </div>
  );
};
