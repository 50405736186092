import React from "react";
import { Link } from "react-router-dom";
import { palette } from "../../theme/theme";

export const LoginForm = ({
  handleChange,
  handleSubmit,
  loading,
  credentials,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      action="https://par.tuclase.cl/login/index.php"
      method="post"
    >
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="text"
          name="username"
          className="form-control box-ingreso2"
          placeholder="Usuario"
          value={credentials.username}
        />
      </div>
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="password"
          className="form-control box-ingreso2"
          placeholder="Contraseña"
          value={credentials.password}
        />
      </div>
      <div className="col-md-12">
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block bot-text"
          style={{ backgroundColor: palette.blue }}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-md mx-3"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Ingresa"
          )}
        </button>
      </div>
      <div className="col-md-12">
        <p className="cuenta">
          <Link to="/change-password" style={{ color: "#B5D3EB" }}>
            Establecer o restablecer contraseña
          </Link>
        </p>
      </div>
    </form>
  );
};
