import logo_footer from "./assets/logo-footer3.png";
import ico_face from "./assets/ico-face.png";
import ico_you from "./assets/ico-you.png";
import ico_inst from "./assets/ico-inst.png";
import logo_tipddy from "./assets/logo-tipddy.png";
import "./index.css";
import { useTheme } from "../../contexts/ThemeContext";
import { Link } from "react-router-dom";

export const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <div className="wrapper fon-footer sale-movil" style={theme.styles.nav}>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img src={logo_footer} alt="..." className="" />
              <br />
              <br />
            </div>
            <div className="col-md-3">
              <p className="tit-footer">Accesos</p>
              <ul className="esp-footer ul-col">
                <li className="menu-footer">
                  <Link to={"/listcourses/progress"}>Historial</Link>
                </li>
                {/*               <li className="menu-footer">Malla</li>
              <li className="menu-footer">DNC</li> */}
              </ul>
            </div>
            {/*             <div className="col-md-3">
              <p className="tit-footer">Aula Virtual</p>
              <ul className="esp-footer ul-col">
                <li className="menu-footer">Av. Zañartur 989</li>
                <li className="menu-footer">Ñuñoa - Santiago - Chile</li>
                <li className="menu-footer">Info"typddy.cl</li>
              </ul>
            </div> */}
            <div className="col-md-3" style={{ marginLeft: "auto" }}>
              <p className="tit-footer">Contacto</p>
              <ul className="esp-footer">
                <li className="menu-footer-icon">
                  <a
                    href="https://www.facebook.com/tuclasetupais"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_face} alt="..." className="img-rounded" />
                  </a>
                </li>
                <li className="menu-footer-icon">
                  <a
                    href="https://www.youtube.com/c/tuclasetupais"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_you} alt="..." className="img-rounded" />
                  </a>
                </li>
                <li className="menu-footer-icon">
                  <a
                    href="https://www.instagram.com/tuclasetupais"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_inst} alt="..." className="img-rounded" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="wrapper fon-pie tipddy-p sale-movil"
        style={theme.styles.nav}
      >
        <p className="tipddy">
          <img src={logo_tipddy} alt="..." className="img-rounded" />
          Copyright Powered by Tipddy
        </p>
      </div>

      <div className="wrapper fon-pie2 sale-desktop entra-movil">
        <img src={logo_footer} alt="..." className="logo-tipddy" />
      </div>

      <div className="wrapper fon-pie3 sale-desktop entra-movil">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-6">
              <p className="tit-footer">Accesos</p>
              <ul className="esp-footer">
                <li className="menu-footer"></li>
                <li className="menu-footer">
                  <Link to={"/listcourses/progress"}>Historial</Link>
                </li>
              </ul>
            </div>
            {/*             <div className="col-6 col-md-6">
              <p className="tit-footer">Aula Virtual</p>
              <ul className="esp-footer">
                <li className="menu-footer">Av. Zañartur 989</li>
                <li className="menu-footer">Ñuñoa - Santiago - Chile</li>
                <li className="menu-footer">Info"typddy.cl</li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <div className="wrapper tipddy-p fon-pie sale-desktop entra-movil">
        <p className="tipddy2">
          <img src={logo_tipddy} alt="..." className="img-rounded" />
          Copyright Powered by Tipddy
        </p>
      </div>
    </>
  );
};
