import React from "react";
import { mount, query } from "../utils";
import platform from "platform";
import { useMoodle } from "../contexts/MoodleContext";

export const useWidget = (auth) => {
  const moodle = useMoodle();
  /*   console.log(auth); */
  const body = query("body");

  const scriptWidget = `
    window.fwSettings = {
      widget_id: 62000001419,
    };
    !(function () {
      if ("function" != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
  `;

  function privateWidget() {
    window.FreshworksWidget("prefill", "ticketForm", {
      name: `${auth.user.fullname}`,
      email: `${auth.user.email}`,
      subject: "",
      description: "",
      group_id: 62000092292,
      description_text: "prueba description text",
      priority: 1,
      status: 2,
      type: "Question",
      custom_fields: {
        cf_rut: `${auth.user.idnumber}`,
        cf_nota: "",
      },
    });
    window.FreshworksWidget("hide", "ticketForm", [
      "custom_fields.cf_plataforma",
    ]);
    window.FreshworksWidget("disable", "ticketForm", [
      "name",
      "email",
      "custom_fields.cf_rut",
      "custom_fields.cf_nota",
    ]);
  }

  function publicWidget() {
    window.FreshworksWidget("prefill", "ticketForm", {
      name: "",
      email: "",
      subject: "",
      description: "",
      priority: 1,
      group_id: 62000092292,
      status: 2,
      type: "Question",
      custom_fields: {
        cf_rut: "",
        cf_nota: "",
      },
    });
    window.FreshworksWidget("hide", "ticketForm", [
      "custom_fields.cf_plataforma",
    ]);
  }

  function courseFocusWidget(id) {
    let course = moodle.courses.filter((c) => c.id === Number(id))[0];
    /*     console.log(course); */
    window.FreshworksWidget("prefill", "ticketForm", {
      name: `${auth.user.fullname}`,
      email: `${auth.user.email}`,
      subject: "",
      description: "",
      group_id: 62000092292,
      priority: 1,
      status: 2,
      type: "Question",
      custom_fields: {
        cf_rut: `${auth.user.idnumber}`,
        cf_nota: `Id Curso: ${id} - Nombre Curso: ${course.fullname} - Navegador: ${platform.name} - OS: ${platform.description}`,
      },
    });
    window.FreshworksWidget("hide", "ticketForm", [
      "custom_fields.cf_plataforma",
    ]);
    window.FreshworksWidget("disable", "ticketForm", [
      "name",
      "email",
      "custom_fields.cf_rut",
    ]);
  }

  function isCourseFocus() {
    let pathname = window.location.pathname;
    if (pathname.includes("detailcourse")) {
      let data = pathname.split("/");
      let id = data[data.length - 1];
      return id;
    }
    return false;
  }

  React.useEffect(() => {
    if (auth.user) {
      !moodle.courses && moodle.getCourses();
    }
    if (!window.fwSettings) {
      let script = mount("script");
      let js = document.createTextNode(scriptWidget);
      script.appendChild(js);
      body.appendChild(script);
      let cdn = document.createElement("script");
      cdn.src = "https://widget.freshworks.com/widgets/62000001419.js";
      cdn.async = true;
      cdn.defer = true;

      body.appendChild(cdn);
    }
    if (auth.user && isCourseFocus() && moodle.courses) {
      window.FreshworksWidget("close");
      courseFocusWidget(isCourseFocus());
      return;
    }
    if (auth.user) {
      window.FreshworksWidget("close");
      privateWidget();
      return;
    }
    if (!auth.user) {
      publicWidget();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, window.location.pathname, moodle.courses]);
};
