import { useTheme } from "../../contexts/ThemeContext";

export const ResumeCourses = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <div className="vacio"></div>
      <div className="container">
        <h1 className="tit-color" style={theme.styles.title_text}>
          Mis Cursos
        </h1>
        {/*         <p className="bajada-tit" style={theme.styles.text}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor.{" "}
        </p> */}
      </div>
      <div className="vacio"></div>
      <div className="wrapper fon-sec-car" style={theme.styles.bg_course_group}>
        <div className="container">
          <div className="col-md-12">
            <div className="row d-flex justify-content-center">
              <div className="row">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper fon-sec-car22 sale-desktop entra-movil">
        <div className="container">
          <p className="cargar-mas">Cargar más</p>
        </div>
      </div>
      <div className="vacio"></div>
    </>
  );
};
