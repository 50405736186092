import React from "react";
import { fetcher } from "../utils";

export const useValidToken = (query) => {
  const [isValid, setValid] = React.useState(false);
  const [data, setData] = React.useState(null);
  const URL =
    "https://par.dunalastair.cl/tipddy/admin/iapp/services/restore-password.php/user/validtoken";

  React.useEffect(() => {
    let resources = `${URL}${query}`;
    fetcher(resources, { method: "GET" })
      .then((data) => {
        /*         console.log(data); */
        setData(data);
        setValid(true);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isValid,
  };
};
