export const THEME_SEVERITY = {
  DEFAULT: "#fff",
  CLEAR: "#f8f9fa",
  DARK: "#546e7a",
  INFO: "#0d6efd",
  WARNING: "#ffc107",
  ERROR: "#dc3545",
  SUCCESS: "#198754",
};

export const DARK_MODE = {
  PRIMARY: "#333333",
  SECONDARY: "rgb(71 93 116)",
};

export const THEME_CONTEXT = {
  NAV_LIGHT: "#FAFAFA",
  NAV_DARK: DARK_MODE.PRIMARY,
  DAY_MODE_BTN: "#fdbf00",
  DARK_MODE_BTN: DARK_MODE.SECONDARY,
  TITLE_DAY: "#666666",
  TITLE_DARK: "#fff",
  TEXT_DAY: "#666666",
  TEXT_DARK: "#a19b9c",
};
