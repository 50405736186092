import React, { useState } from "react";
import { Navbar } from "../../components/Navbar";
import { HeaderCourses } from "../../components/HeaderCourses";
import { ResumeCourses } from "../../components/ResumeCourses";
import { CoursesContentSection } from "../../components/CoursesContentSection";
import { Footer } from "../../components/Footer";
import { CourseCard } from "../../components/CourseCard";
import { useMoodle } from "../../contexts/MoodleContext";
import { CustomSpinner } from "../../components/Loader/custom.spinner";
import { FormSence } from "../../services/sence/form";
import { useNavigate } from "react-router-dom";
import { CustomDialog } from "../../components/Dialog";
/* import { defaultSenceValidation } from "../../utils/handler";
import { getUserNru } from "../../services/tipddy/nru"; */
import { useAuth } from "../../contexts/AuthContext";
import { STRINGS } from "../../utils/strings";
import { palette } from "../../theme/theme";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { loginMoodle, resetPasword } from "../../services/moodle/userauth";
import { Snackbar } from "../../components/Snackbar";
import {
  DEFAULT_SNACKBAR,
  SUCCESS_FORCEPASSWORD_CHANGE,
} from "../../utils/snackbar";

const ListCourses = () => {
  /*   if (localStorage.getItem(STRINGS.FORCEPASSWORD_ERROR_CODE))
    window.location.replace(`/${STRINGS.FORCEPASSWORD_ERROR_CODE}`); */

  // const auth = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [modalForcePassword, showModalForcePassword] = useState(false);
  const navigate = useNavigate();
  const isSence = localStorage.getItem("sence_session");
  const currentCourse = localStorage.getItem("current_course");
  const moodle = useMoodle();
  const [msg, setMsg] = React.useState({
    open: false,
    title: "",
    msg: "",
    confirm: "Ir a Home",
    cancel: "Ir al curso",
  });

  React.useEffect(() => {
    // !moodle.courses && moodle.getCourses();
    moodle.getCourses();
    if (localStorage.getItem(STRINGS.FORCEPASSWORD_ERROR_CODE)) {
      showModalForcePassword(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (moodle.courses) {
      const size = moodle.courses.length;
      if (size === 1) {
        setLoading(false);
        navigate(`/detailcourse/${moodle.courses[0].id}`);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [moodle.courses]);

  React.useEffect(() => {
    if (isSence) navigate(`/detailcourse/${currentCourse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCancel() {
    setMsg({ ...msg, open: false });
    navigate(`/detailcourse/${moodle.courses[0].id}`);
  }

  function onConfirm() {
    setMsg({ ...msg, open: false });
  }

  const PageView = () => {
    return (
      <>
        <FormSence />
        <Navbar />
        <HeaderCourses moodle={moodle} />
        <ResumeCourses>
          {moodle.courses &&
            moodle.courses.length > 0 &&
            moodle.courses.map((item, idx) => {
              return <CourseCard key={idx} item={item} moodle={moodle} />;
            })}
        </ResumeCourses>
        <CoursesContentSection />
        <Footer />
        <CustomDialog
          open={msg.open}
          onConfirm={onConfirm}
          onCancel={onCancel}
          title={msg.title}
          confirm={msg.confirm}
          cancel={msg.cancel}
          courseid={
            moodle &&
            moodle.course &&
            moodle.courses.length > 0 &&
            moodle.courses[0].id
          }
          moodle={moodle}
          countdown
        />
        <ForcePasswordModal open={modalForcePassword} />
      </>
    );
  };

  return <>{loading ? <CustomSpinner /> : <PageView />}</>;
};
export default ListCourses;

const ForcePasswordModal = ({ open }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    severity: "",
    visible: false,
    message: "",
  });
  const [errorAuth, setErrorAuth] = useState({
    error: false,
    message: "",
  });
  const [credentials, setCredentials] = useState({
    lastPassword: "",
    newPassWord: "",
    confirmPassword: "",
  });

  const handleSubmit = async () => {
    let username = localStorage.getItem("username");
    // let token = localStorage.getItem("token");
    try {
      await loginMoodle({ username, password: credentials.lastPassword });
      let reseted = await resetPasword({
        username,
        password: credentials.newPassWord,
        forcepasswordchange: 1,
      });

      localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);

      setSnackbar(SUCCESS_FORCEPASSWORD_CHANGE);

      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
        auth.logout();
        navigate("/");
      }, 5000);

      console.log({ reseted });
    } catch (err) {
      setErrorAuth({
        error: true,
        message: "Error al validar la contraseña anterior",
      });
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth
          open={open}
          /* onClose={handleClose} */ PaperProps={{
            style: {
              backgroundColor: palette.turqoise,
              paddingBottom: ".7rem",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: palette.blue,
              color: "#fff",
              fontSize: 16,
              fontFamily:
                "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
            }}
          >
            Crea una nueva contraseña
          </DialogTitle>
          <DialogContent className="mt-3">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Contraseña actual"
              type="email"
              fullWidth
              variant="standard"
              value={credentials.lastPassword}
              onChange={(e) =>
                setCredentials({ ...credentials, lastPassword: e.target.value })
              }
              inputProps={{ style: { fontSize: 12, color: palette.blue } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: palette.blue } }}
            />
            {errorAuth.error && (
              <p style={{ fontSize: 12, color: "red", fontWeight: 400 }}>
                {errorAuth.message}
              </p>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nueva contraseña"
              type="email"
              fullWidth
              variant="standard"
              value={credentials.newPassWord}
              onChange={(e) =>
                setCredentials({ ...credentials, newPassWord: e.target.value })
              }
              inputProps={{ style: { fontSize: 12, color: palette.blue } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: palette.blue } }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Confirmar contraseña"
              type="email"
              fullWidth
              variant="standard"
              value={credentials.confirmPassword}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  confirmPassword: e.target.value,
                })
              }
              inputProps={{ style: { fontSize: 12, color: palette.blue } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: palette.blue } }}
            />
          </DialogContent>
          <DialogActions>
            {
              <Button
                disabled={Object.values(credentials).some((v) => v === "")}
                /* onClick={handleClose} */ onClick={handleSubmit}
                variant="contained"
                sx={{
                  backgroundColor: palette.blue,
                  fontSize: 12,
                  marginRight: 2,
                }}
              >
                Confirmar
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};
