import flecha_s from "../HeaderCourses/assets/flecha-s.png";
import img_interior from "./assets/img-inferior.png";
import { useTheme } from "../../contexts/ThemeContext";

export const CoursesContentSection = () => {
  const theme = useTheme();
  const url = "https://www.tuclase.cl/";

  const handleClick = () => window.open(url, "_blank");

  return (
    <>
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-7">
              <img src={img_interior} alt="..." className="img-fluid" />
            </div>
            <div className="col-md-5 sec-esp">
              <h1 className="tit-color" style={theme.styles.title_text}>
                Cursos Disponibles
              </h1>
              {/*               <p className="bajada-tit" style={theme.styles.text}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec,
              </p> */}

              <p className="bajada-tit my-4">
                <button
                  onClick={handleClick}
                  type="button"
                  className="btn btn-primary bot-generico"
                  style={theme.styles.darkmodebtn2}
                >
                  <img src={flecha_s} alt="..." className="" />
                  Contacto
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="vacio"></div>
    </>
  );
};
